<template>
	<main class="page-container">
		<div class="page-header-banner">
			<picture>
				<source media="(max-width: 800px)" srcset="@/assets/img/@tmp/banner_sample_mo.jpg" />
				<source media="(min-width: 801px)" srcset="@/assets/img/@tmp/banner_sample_pc.jpg" />
				<img src="@/assets/img/@tmp/banner_sample_pc.jpg" alt="" />
			</picture>
		</div>
		<div class="page-body">
			<!-- 지원사업 LNB -->
			<lnb-support-smb-sales className="" :openVisible="true" />
			<!-- //지원사업 LNB -->

			<div class="container">
				<div class="card-list-wrapper">
					<div class="page-header">
						<h3 class="page-header-title">
							<router-link to="/support/smb-sales" class="button-link-home">
								<span class="sr-only">홈으로 이동</span>
							</router-link>
							소상공인 인플루언서 교육
						</h3>
					</div>
					<div class="list-header">
						<template>
							<div class="header-column">
								<p class="list-header-title">내역사업별 우수 산출물</p>
							</div>
							<div class="header-column">
								<ul class="sort-list">
									<li class="sort-item is-active"><button type="button" @click.prevent="selectSort('')">최신순</button></li>
									<li class="sort-item"><button type="button" @click.prevent="selectSort('I')">인기순</button></li>
								</ul>
							</div>
						</template>
					</div>
					<common-list :div-class="['list-body']" :is-loading="isLoading" :is-no-result="isNoResult">
						<card-list :items="items" :showTitle="true" />
					</common-list>
				</div>
				<portal-pagination page-name="InfoList" :pageListSize="pageListSize" :total-count="listTotal" :page-size="pageSize" :page-no="pageNo" :query="queries"></portal-pagination>
			</div>
		</div>
	</main>
</template>

<script>
import { ACT_GET_ISSUE_LIST } from '@/store/_act_consts';
import { getItems, lengthCheck, setPaging, tryResCallback } from '@/assets/js/utils';
import CardList from '@/components/information/CardList';
import NoResult from '@/components/common/NoResult';
import CommonList from '@/components/common/CommonList';

import LnbSupportSmbSales from '@/components/support/LnbSupportSmbSales';
import PortalPagination from '@/components/PortalPagination';
import { mapGetters } from 'vuex';
import menus from '@/assets/js/menus';

export default {
	name: 'SupportSmbSalesList',
	components: {
		LnbSupportSmbSales,
		NoResult,
		CommonList,
		CardList,
		PortalPagination,
	},
	data: () => ({
		hasMore: false,
		totalCount: 0,
		pageListSize: 10,
		pageSize: 12,
		pageNo: 1,
		listTotal: 20,
		sortCd: '',
		isNoResult: false,
		isLoading: false,
		plygrndCtgrDcd: 1,
		ctgrItems: [
			{
				id: '1',
				text: '유통ㆍ마케팅 트렌드, 노무ㆍ세무',
			},
			{
				id: '2',
				text: '정책동향',
			},
			{
				id: '3',
				text: '뉴스레터',
			},
			{
				id: '4',
				text: '기타 콘텐츠',
			},
		],
		items: [
			{ issueId: 59, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. ', issueSmlTit: '22-26', issueCn: null, issueNo: '22-26', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/e2250810.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990539137, preId: 0, nextId: 0 },
			{ issueId: 58, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-25', issueCn: null, issueNo: '22-25', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/235a9f8d.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990550957, preId: 0, nextId: 0 },
			{ issueId: 57, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-24', issueCn: null, issueNo: '22-24', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/cc5b4abd.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990568737, preId: 0, nextId: 0 },
			{ issueId: 56, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-23', issueCn: null, issueNo: '22-23', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/609847d2.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990617753, preId: 0, nextId: 0 },
			{ issueId: 59, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. ', issueSmlTit: '22-26', issueCn: null, issueNo: '22-26', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/e2250810.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990539137, preId: 0, nextId: 0 },
			{ issueId: 58, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-25', issueCn: null, issueNo: '22-25', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/235a9f8d.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990550957, preId: 0, nextId: 0 },
			{ issueId: 57, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-24', issueCn: null, issueNo: '22-24', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/cc5b4abd.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990568737, preId: 0, nextId: 0 },
			{ issueId: 56, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-23', issueCn: null, issueNo: '22-23', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/609847d2.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990617753, preId: 0, nextId: 0 },
			{ issueId: 59, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. ', issueSmlTit: '22-26', issueCn: null, issueNo: '22-26', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/e2250810.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990539137, preId: 0, nextId: 0 },
			{ issueId: 58, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-25', issueCn: null, issueNo: '22-25', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/235a9f8d.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990550957, preId: 0, nextId: 0 },
			{ issueId: 57, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-24', issueCn: null, issueNo: '22-24', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/cc5b4abd.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990568737, preId: 0, nextId: 0 },
			{ issueId: 56, issueTit: '콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다. 콘텐츠 제목입니다.', issueSmlTit: '22-23', issueCn: null, issueNo: '22-23', rprsImgPtUrl: 'https://gov-api-storage.cloud.toast.com/v1/AUTH_56d2b0234b164debb9fc52797c5f3c61/test/admin/issue/rprs/20465/2023/09/06/609847d2.jpg', rprsImgFileNm: null, thmbnlImgPtUrl: null, thmbnlImgFileNm: null, atchFilePtUrl: null, atchFileNm: null, inqCnt: 0, expsrYn: 'Y', mdfcnDt: 1693990617753, preId: 0, nextId: 0 },
		],

		tryCount: 0,
		menus: menus.filter((x) => x.code === 'information'), //모바일 중메뉴 데이터 (20220714 - hib)
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		queries() {
			const result = {};
			return result;
		},
	},
	created() {
		this.getIssueList();
	},
	methods: {
		getIssueList(isInit) {
			if (isInit) {
				// this.items = [];
				this.pageNo = 1;
			}
			this.$store
				.dispatch(`information/${ACT_GET_ISSUE_LIST}`, {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
					sortCd: this.sortCd,
				})
				.then((res) => {
					tryResCallback(this.tryCount, res, this.getIssueList, this.setIssue);
				})
				.catch((e) => {
					console.error(e);
				});
		},
		setIssue(res) {
			if (lengthCheck(res)) {
				// this.items = this.items.concat(getItems(res));
			}
			setPaging(this, res);
		},
		morePage() {
			if (this.hasMore) this.pageNo++;
			this.getIssueList();
		},
		selectSort(el) {
			if (el === 'I') {
				this.sortCd = 'I';
			} else {
				this.sortCd = '';
			}
			this.getIssueList(true);
		},
		/* 중메뉴이동 (20220714 - hib) */
		click() {
			let element = document.getElementById('select_list');
			element.classList.toggle('show_list');
			let element02 = document.getElementById('select_btn');
			element02.classList.toggle('active');
		},
	},
};
</script>
